<template>
  <div>
    <NotFound/>
  </div>
</template>

<script>
import NotFound from '@/components/NotFound.vue'

export default {
  components: {
    NotFound
  }
}
</script>
